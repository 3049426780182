
export const toStrapiDateFormat = (date: string) => {
    const d = new Date(date)
    return d.toLocaleDateString("sv-SE")
}

export const addDayForGcal = (date: string) => {
    /**
     * Gcal end date is exclusive so need to add one
     */
    const d = new Date(date)
    d.setDate(d.getDate() + 1)
    return d.toISOString()
}
