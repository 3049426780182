import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { createEvent } from '../backendApi/eventsApi'
import { fetchUser } from '../backendApi/userApi'
import useAuth from '../hooks/useAuth'
import { toStrapiDateFormat } from '../utils/date'

const EventCreate = () => {
    const { state } = useAuth()
    const [user, setUser] = useState(null)

    const [title, setTitle] = useState('')
    const [eventType, setEventType] = useState('caving')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [location, setLocation] = useState('')
    const [description, setDescription] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const handler = func => event => func(event.target.value)

    const handleSubmit = async (event) => {
        event.preventDefault()
        const eventDetails = {
            data: {
                eventType,
                title,
                startDate: toStrapiDateFormat(startDate),
                endDate: toStrapiDateFormat(endDate),
                location,
                description,
                eventCreator: {
                    name,
                    email,
                    phoneNumber
                },
                eventCreatedBy: user.id
            }
        };
        (async () => {
            const res = await createEvent(eventDetails)
            if (res?.data != null) {
              alert("Event successfully created.")
              navigate(`/app/event-signup/${res.data.id}`)
            } else {
                alert(`There was a problem: ${res.error.message}`)
            }
        })()
    }

    useEffect(() => {
        (async () => {
          const res = await fetchUser()
          setUser(res)
        })()
      }, [])

    useEffect(() => {
        if (eventType != "external" && user) {
            setName(`${user.personalDetails.firstName} ${user.personalDetails.lastName}`)
            setEmail(user.email)
            setPhoneNumber(user.personalDetails.phoneNumberOne)
        } else {
            setName('')
            setEmail('')
            setPhoneNumber('')
        }
    }, [eventType, user])
      
    return (
        <>
            <h1>
                Create an Event
            </h1>
            <p>
                Still deciding the best way for external people to book the cottage but for now it'll be an email to the cottage sec and they can make the event
            </p>
            <form onSubmit={handleSubmit}>
                <h2>Event Details</h2>
                <div>
                    <label>
                        Event Title
                        <input required onChange={handler(setTitle)} value={title} type="text"/>
                    </label>
                </div>
                <div>
                    <label>
                        Event type: (if non-wsg booking please select external)
                        <select 
                            onChange={handler(setEventType)}
                            defaultValue="caving"
                            value={eventType}
                            >
                            <option value="caving">Caving</option>
                            <option value="social">Social</option>
                            <option value="external">External</option>
                        </select>
                    </label>
                </div>
                <div>
                    <label>
                        Start Date:
                        <input required onChange={handler(setStartDate)} value={startDate} type="date"/>
                    </label>
                </div>
                <div>
                    <label>
                        End Date:
                        <input required onChange={handler(setEndDate)} value={endDate} type="date"/>
                    </label>
                </div>
                <div>
                    <label>
                        Location:
                        <input required onChange={handler(setLocation)} value={location} type="text"/>
                    </label>
                </div>
                <div>
                    <label>
                        Description (this will be a proper editor at some point):
                        <br></br>
                        <textarea required onChange={handler(setDescription)} value={description}/>
                    </label>
                </div>
                <h2>
                    Event Creator
                </h2>
                <div>
                    <label>
                        Name:
                        <input required onChange={handler(setName)} value={name} type="text"/>
                    </label>
                </div>
                <div>
                    <label>
                        Email (only shared with cottage secretary):
                        <input required onChange={handler(setEmail)} value={email} type="email"/>
                    </label>
                </div>
                <div>
                    <label>
                        Phone number (only shared with cottage secretary):
                        <input onChange={handler(setPhoneNumber)} value={phoneNumber} type="text"/>
                    </label>
                </div>
                <button>
                    Submit
                </button>
            </form>
        </>
    )
}


export default EventCreate 
