import React from 'react'
import * as styles from "./eventSignupTable.module.css"

const EventSignupTable = ({ attendees }) => {
    if (!attendees || attendees.length == 0) {
        return null
    }
    return (
            <div>
                <h2>
                    Who's going?
                </h2>
                <div className={styles.signupTable}>
                    <table id="signupTable">
                        <thead>
                            <tr>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Bed required?
                                </th>
                                <th>
                                    Number of nights
                                </th>
                                <th>
                                    Lift required?
                                </th>
                                <th className={styles.freeTextCell}>
                                    Offering a lift from
                                </th>
                                <th className={styles.freeTextCell}>
                                    Needing a lift from
                                </th>
                                <th>
                                    Caving level
                                </th>
                                <th>
                                    Need to borrow kit?
                                </th>
                                <th className={styles.freeTextCell}>
                                    What kit is needed
                                </th>
                                <th className={styles.freeTextCell}>
                                    Dietary requirements
                                </th>
                                <th>
                                    Can rig?
                                </th>
                                <th>
                                    Can lead?
                                </th>
                                <th className={styles.freeTextCell}>
                                    Any comments
                                </th>
                                <th>
                                    Phone number
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { attendees && attendees.length > 0 &&
                                attendees.map((attendee, i) => (
                                    <tr key={i}>
                                        <td>
                                            { attendee.isGuest ? attendee.name :
                                                `${attendee.User.data.attributes.personalDetails.firstName} ${attendee.User.data.attributes.personalDetails.lastName}`
                                            }
                                        </td>
                                        <td>
                                            <input type="checkbox" readOnly checked={attendee.bedRequired} />
                                        </td>
                                        <td>
                                            {attendee.noOfNights}
                                        </td>
                                        <td>
                                            <input type="checkbox" readOnly checked={attendee.needALift} />
                                        </td>
                                        <td>
                                            {attendee.liftOffer}
                                        </td>
                                        <td>
                                            {attendee.liftRequest}
                                        </td>
                                        <td>
                                        <select 
                                            value={attendee.cavingLevel}
                                            readOnly
                                        >
                                            <option value="0">Beginner</option>
                                            <option value="1">Intermediate</option>
                                            <option value="2">Advanced</option>
                                            <option value="3">SpeleoPro</option>
                                        </select>
                                        </td>
                                        <td>
                                            <input type="checkbox" readOnly checked={attendee.borrowKit} />
                                        </td>
                                        <td>
                                            {attendee.borrowKitDetails}
                                        </td>
                                        <td>
                                            {attendee.dietaryReqs}
                                        </td>
                                        <td>
                                            <input type="checkbox" readOnly checked={attendee.canRig} />
                                        </td>
                                        <td>
                                            <input type="checkbox" readOnly checked={attendee.canLead} />
                                        </td>
                                        <td>
                                            {attendee.comments}
                                        </td>
                                        <td>

                                            { attendee.isGuest ? attendee.phoneNumber : attendee.User.data.attributes.personalDetails.phoneNumberOne}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    )
}



export default EventSignupTable 
