import { getJWT } from "../hooks/useAuth"
const baseURL = process.env.GATSBY_STRAPI_API_URL
const cavesURL = `${baseURL}/api/caves`

export const createCave = async (cave) => {
    const response = await fetch(`${cavesURL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        },
        body: JSON.stringify({
            "data": cave
        })
    })
    return response.json()
}

export const fetchCaveList = async () => {
    const response = await fetch(`${cavesURL}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        }
    })
    return response.json()
}

