import React, { useEffect, useState } from 'react'
import { fetchSingleEvent } from '../backendApi/eventsApi'
import useAuth from '../hooks/useAuth'
import StrapiEvent from '../types/StrapiEvent'
import EventSignupDetails from './eventSignup/EventSignupDetails'
import EventSignupTable from './eventSignup/EventSignupTable'
import Layout from './layout'
import NavBar from './navBar/navBar'

const EventSignup = ({id}) => {
    const { state } = useAuth()
    const [event, setEvent] = useState<StrapiEvent>({})

    useEffect(() => {
      (async () => {
        const res = await fetchSingleEvent(id)
        setEvent(res.data as StrapiEvent)
      })()
    }, [])
    return (
        <>
            <div>
                <h1>
                    {event?.attributes?.title}
                </h1>
                <h3>
                    Dates: {new Date(event?.attributes?.startDate).toLocaleDateString("en-GB")} - {new Date(event?.attributes?.endDate).toLocaleDateString("en-GB")}
                </h3>
                <h3>
                    Location: {event?.attributes?.location}
                </h3>
                <p>
                    {event?.attributes?.description}
                </p>
                <p>
                    <small>Trip created by: {event?.attributes?.eventCreator?.name}</small>
                </p>
            </div>
            <EventSignupTable attendees={event?.attributes?.attendee} />
            <EventSignupDetails eventId={id} />
        </>
    )
}



export default EventSignup 
