import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import useAuth from "../hooks/useAuth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const { state, isAuthenticated } = useAuth()

    const redirect = location.pathname.replace("/app/", "")
    if (!isAuthenticated) {
        navigate('/login', { state: { redirect }});
        return null
    }


    return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
