import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { deleteAttendee, deleteEvent, fetchCreatedUpcomingTrips, fetchPersonalUpcomingTrips } from '../backendApi/eventsApi'
import { fetchUser } from '../backendApi/userApi'
import useAuth from '../hooks/useAuth'
import Layout from './layout'
import NavBar from './navBar/navBar'


const PersonalEventList = (id) => {
    const [trips, setTrips] = useState([])
    const [createdTrips, setCreatedTrips] = useState([])
    
    useEffect(() => {
        (async () => {
          const res = await fetchPersonalUpcomingTrips(id)
          setTrips(res.data)
        })()
    }, [])
    
    useEffect(() => {
        (async () => {
          const res = await fetchCreatedUpcomingTrips(id)
          setCreatedTrips(res.data)
        })()
    }, [])

    return (
        <>  
            {createdTrips && createdTrips.length > 0 && <ManageCreatedTrips trips={createdTrips} />}
            {trips && trips.length > 0 && <PersonalUpcomingTrips trips={trips} />}
        </>
    )
}

const PersonalUpcomingTrips = ({trips}) => (
    <>
        <h3>Your upcoming trips</h3>
        {
            trips.map((trip, i) => {
                return (
                    <>
                        <li>
                        {new Date(trip.attributes.startDate).toLocaleDateString("en-GB")} - {new Date(trip.attributes.endDate).toLocaleDateString("en-GB")}: {trip.attributes.title}
                            <span style={{ marginLeft: "15px"}}>
                                <button onClick={() => navigate(`/app/event-signup/${trip.id}`)}>View details</button>
                            </span>
                        </li>
                    </>
                )
            })
        }
    </>
)

const ManageCreatedTrips = ({trips}) => {

    const handleDelete = async (eventId) => {
        (async () => {
            if (window.confirm("Are you sure you want to delete this trip")) {
                const res = await deleteEvent(eventId)
                location.reload()
            }
        })()
    }

    return (
        <>
            <h3>Manage your trips</h3>
            {
                trips.map((trip, i) => {
                    return (
                        <>
                            <li key={i}>
                            {new Date(trip.attributes.startDate).toLocaleDateString("en-GB")} - {new Date(trip.attributes.endDate).toLocaleDateString("en-GB")}: {trip.attributes.title}
                                <span style={{ marginLeft: "15px"}}>
                                    <button onClick={() => {handleDelete(trip.id)}}>Delete trip</button>
                                </span>
                            </li>
                        </>
                    )
                })
            }
        </>
    )
}


export default PersonalEventList 
