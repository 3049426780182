import React from "react"
import { Router } from "@reach/router"
import Layout from "../../components/layout"
import Profile from "../../components/Profile"
import EventSignup from "../../components/EventSignup"
import PrivateRoute from "../../components/PrivateRoute"
import NavBar from "../../components/navBar/navBar"
import EventCreate from "../../components/EventCreate"
import TripReportCreate from "../../components/tripReport/TripReportCreate"


const App = ({ location }) => (
  <Layout>
    <NavBar/>
    <Router basepath="/app">
      <PrivateRoute path="/your-profile" location={location} component={Profile} />
      <PrivateRoute path="/event-signup/:id" location={location} component={EventSignup} />
      <PrivateRoute path="/event-create" location={location} component={EventCreate} />
      <PrivateRoute path="/trip-create" location={location} component={TripReportCreate} />
      {/* <Profile path="/profile" /> */}
      {/* <Login path="/login" /> */}
    </Router>
  </Layout>
)

export default App
