import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { createCave, fetchCaveList } from '../../backendApi/caveApi'
import { createTripReport } from '../../backendApi/tripsApi'
import { fetchUser } from '../../backendApi/userApi'
import useAuth from '../../hooks/useAuth'
import { concatName } from "../../utils/user";
import { toStrapiDateFormat } from '../../utils/date'
import Loadable from "@loadable/component"

const ClientSideOnlyLazyEditor = Loadable(() =>
  import("../Editor")
)


const extractMediaIds = (str: string) => {
    return [...str.matchAll(/strapiMediaId=(\d*)\s/g)].map(x => x[1]);
}

const TripReportCreate = () => {
    const { state } = useAuth()

    const [localDesc, setLocalDesc] = useState("")

    const [title, setTitle] = useState('')
    const [cave, setCave] = useState('')
    const [caveList, setCaveList] = useState([])
    const [tripDate, setTripDate] = useState('')
    const [route, setRoute] = useState('')
    const [timeTaken, setTimeTaken] = useState('')
    const [presentOnTrip, setPresentOnTrip] = useState('')
    const [author, setAuthor] = useState('')

    const handler = func => event => func(event.target.value)
    const selectHandler = event => {
        if (event.target.value.length < 3) return 
        const subs = event.target.value.split(" - ")
        if (subs.length == 1) {
            const id = caveList.find(x => {
                if (x.attributes.name == subs[0]) setCave(x.id)
            })
        } else {
            const id = caveList.find(x => {
                if (x.attributes.name == subs[1]) setCave(x.id)
            })
        }
    }

    const cavePrompt = async () => {
        const cave = {
            name: null,
            nickname: null,
            location: null,
            region: null
        }
        cave.name = await prompt("Enter the full name of the cave (e.g. Ogof Fynnon Ddu 1)")
        if (!cave.name) return
        cave.region = await prompt("Enter the region of the cave (e.g. South Wales)")
        if (!cave.region) return
        cave.nickname = await prompt("Optional: Enter the nickname for the cave, if there is one (e.g. OFD 1) or leave blank")
        if (cave.nickname === null) return
        cave.location = await prompt("Optional: Enter a more specific location (e.g. Leck fell) or leave blank")
        if (cave.location === null) return
    
        const res = await createCave(cave)
        if (res?.data != null) {
            alert("Cave successfully created. Please select it from the dropdown")
            const res = await fetchCaveList()
            setCaveList(res.data)
        } else {
            alert(`There was a problem: ${res.error.message}`)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const eventDetails = {
            data: {
                title,
                cave: cave,
                date: toStrapiDateFormat(tripDate),
                route,
                timeTaken,
                presentOnTrip,
                description: localDesc,
                author,
                images: extractMediaIds(localDesc)
            }
        };
        (async () => {
            const res = await createTripReport(eventDetails)
            if (res?.data != null) {
                alert("Trip report successfully created. It will be available to view in ~10 minutes.")
                window.localStorage.removeItem("description")
                navigate(`/trip-reports`)
            } else {
                alert(`There was a problem: ${res.error.message}`)
            }
        })()
    }

    useEffect(() => {
        (async () => {
          const res = await fetchUser()
          const authorName = concatName(res.personalDetails)
          setAuthor(authorName)
        })()
      }, [])

    useEffect(() => {
        (async () => {
          const res = await fetchCaveList()
          setCaveList(res.data)
        })()
      }, [])  

    return (
        <>
            <h1>
                Create a trip report
            </h1>
            <form onSubmit={handleSubmit}>
                <h2>Details</h2>
                <div>
                    <label>
                        Trip Report Title*: 
                        <input required onChange={handler(setTitle)} value={title} type="text" />
                    </label>
                </div>
                <div>
                    <label>
                        Cave*:
                        <input list="caves" required style={{minWidth:"250px"}} onChange={selectHandler}/>
                        <datalist
                            id="caves"
                        >
                            {
                                caveList && caveList.map((cave, i) => {
                                    return (
                                        <option value={cave.attributes.nickname ? `${cave.attributes.nickname} - ${cave.attributes.name}` : `${cave.attributes.name}`} key={cave.id}>
                                            {
                                                cave.attributes.location ? `${cave.attributes.region} - ${cave.attributes.location}` : `${cave.attributes.region}`
                                            }
                                            
                                        </option>
                                    )
                                })
                            }
                        </datalist>
                    </label>
                    <div>
                        Cave not listed? Add one to the list <a onClick={cavePrompt}>here.</a> Or if someone has added one incorrectly contact the admin.    
                    </div>
                </div>
                <div>
                    <label>
                        Trip Date*:
                        <input required onChange={handler(setTripDate)} value={tripDate} type="date" />
                    </label>
                </div>
                <div>
                    <label>
                        Route taken (e.g. Grand circle):
                        <input onChange={handler(setRoute)} value={route} type="text" />
                    </label>
                </div>
                <div>
                    <label>
                        Time taken:
                        <input onChange={handler(setTimeTaken)} value={timeTaken} type="text" />
                    </label>
                </div>
                <div>
                    <label>
                        Present on trip:
                        <input onChange={handler(setPresentOnTrip)} value={presentOnTrip} type="text" />
                    </label>
                </div>
                <div>
                    <label>
                        Author:
                        <input onChange={handler(setAuthor)} value={author} type="text" />
                    </label>
                </div>
                <div>
                    <label>
                        Description*:
                    </label>
                    <ClientSideOnlyLazyEditor desc={localDesc} setDesc={setLocalDesc} />
                </div>
                <div>
                </div>
                <button>
                    Publish
                </button>
            </form>
        </>
    )
}

export default TripReportCreate

