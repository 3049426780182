import qs from "qs"
import { getJWT } from "../hooks/useAuth"
import { toStrapiDateFormat } from "../utils/date"
const baseURL = process.env.GATSBY_STRAPI_API_URL
const tripReportURL = `${baseURL}/api/trip-reports`

export const createTripReport = async (tripReportDetails) => {
    const response = await fetch(`${tripReportURL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        },
        body: JSON.stringify(tripReportDetails)
    })
    return response.json()
}

export const fetchUpcomingTrips = async () => {
    const query = qs.stringify({
        sort: ['startDate:asc'],
        filters: {
            $or: [
                {
                    eventType: {
                        $eq: "caving"
                    },
                },
                {
                    eventType: {
                        $eq: "social"
                    },
                },
            ],
            startDate: {
                $gte: toStrapiDateFormat(new Date().toDateString())
            }
        },
        pagination: {
            limit: 3
        }
    })
    const response = await fetch(`${eventsURL}?${query}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        }
    })
    return response.json()
}

export const fetchSingleEvent = async (id: number) => {
    const query = qs.stringify({
        populate: [
            "attendee",
            "attendee.User",
            "attendee.User.personalDetails",
            "eventCreator",
        ]
    }, {
        encodeValuesOnly: true,
    })
    const response = await fetch(`${eventsURL}/${id}?${query}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        }
    })
    return response.json()
}

type Attendee = {
    bedRequired: boolean,
    noOfNights: number,
    needALift: boolean,
    liftOffer: string,
    liftRequest: string,
    borrowKit: boolean,
    borrowKitDetails: string,
    dietaryReqs: string,
    cavingLevel: number,
    canRig: boolean,
    canLead: boolean,
    comments: string,
}

export const updateAttendee = async (eventId, attendeeDetails: Attendee) => {
    const query = qs.stringify({
        action: "ATTENDEE_ADD"
    })
    const response = await fetch(`${eventsURL}/${eventId}?${query}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        },
        body: JSON.stringify(attendeeDetails)
    })
    return response.json()
}

export const updateAttendeeGuests = async (eventId, guestList: Attendee[], user) => {
    const arr = guestList.map((guest, i) => {
        return {
            ...guest,
            name: `${guest.name ? guest.name + " " : ""}(Guest of ${user.personalDetails.firstName} ${user.personalDetails.lastName[0]})`,
            isGuest: true
        }
    })
    const query = qs.stringify({
        action: "GUESTS_ADD"
    })
    const response = await fetch(`${eventsURL}/${eventId}?${query}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        },
        body: JSON.stringify(arr)
    })
    return response.json()
}


export const deleteAttendee = async (eventId) => {
    const query = qs.stringify({
        action: "ATTENDEE_DELETE"
    })
    const response = await fetch(`${eventsURL}/${eventId}?${query}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        },
    })
    return response.json()
}

export const deleteEvent = async (eventId) => {
    const query = qs.stringify({
        action: "EVENT_DELETE"
    })
    const response = await fetch(`${eventsURL}/${eventId}?${query}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        },
    })
    return response.json()
}

export const fetchPersonalUpcomingTrips = async (userId) => {
    if (userId === null) {
        return []
    }
    const query = qs.stringify({
        sort: ['startDate:asc'],
        filters: {
            startDate: {
                $gte: toStrapiDateFormat(new Date().toDateString())
            },
            attendee: {
                strapiId: {
                    $eq: userId
                }
            }
        },
        pagination: {
            limit: 10
        }
    })
    const response = await fetch(`${eventsURL}?${query}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        }
    })
    return response.json()
}

export const fetchCreatedUpcomingTrips = async (userId) => {
    if (userId === null) {
        return []
    }
    const query = qs.stringify({
        sort: ['startDate:asc'],
        filters: {
            startDate: {
                $gte: toStrapiDateFormat(new Date().toDateString())
            },
            eventCreatedBy: {
                $eq: userId
            }
        },
    })
    const response = await fetch(`${eventsURL}?${query}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getJWT()}`
        }
    })
    return response.json()
}
