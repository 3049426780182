import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { deleteAttendee, updateAttendee, updateAttendeeGuests } from "../../backendApi/eventsApi"
import GuestSignupDetails from "./GuestSignupDetails"
import { fetchUser } from "../../backendApi/userApi"



const EventSignupDetails = ({ eventId }) => {
    
    const [bedRequired, setBedRequired] = useState(true)
    const [noOfNights, setNoOfNights] = useState(2)
    const [needALift, setNeedALift] = useState(false)
    const [liftOffer, setLiftOffer] = useState('')
    const [liftRequest, setLiftRequest] = useState('')
    const [borrowKit, setBorrowKit] = useState(false)
    const [borrowKitDetails, setBorrowKitDetails] = useState('')
    const [dietaryReqs, setDietaryReqs] = useState('')
    const [cavingLevel, setCavingLevel] = useState(1)
    const [canRig, setCanRig] = useState(false)
    const [canLead, setCanLead] = useState(false)
    const [comments, setComments] = useState('')

    const [guestList, setGuestList] = useState([])

    const [user, setUser] = useState(null)

    useEffect(() => {
        (async () => {
          const res = await fetchUser()
          setUser(res)
        })()
      }, [])

    const handler = func => event => func(event.target.value)

    const attendee = {
        "bedRequired": bedRequired,
        "noOfNights": noOfNights,
        "needALift": needALift,
        "liftOffer": liftOffer,
        "liftRequest": liftRequest,
        "borrowKit": borrowKit,
        "borrowKitDetails": borrowKitDetails,
        "dietaryReqs": dietaryReqs,
        "cavingLevel": cavingLevel,
        "canRig": canRig,
        "canLead": canLead,
        "comments":comments
    };

    const handleSubmit = async (event) => {
        event.preventDefault()
        const x = "what a strange bug";
        (async () => {
            try {
                const res = await updateAttendee(eventId, attendee)
                if (guestList.length > 0) {
                    const res2 = await updateAttendeeGuests(eventId, guestList, user)
                }
                window.location.reload()
            } catch (e) {
                alert("There was a problem adding you or your guests. Please try again or try to remove yourself from the table then try again. If the problem persists please let Nasir know!")
            }
        })()
      }

    const handleDelete = async (event) => {
        event.preventDefault();
        const e = eventId;
        (async () => {
            const res = await deleteAttendee(eventId)
            window.location.reload()
        })()
    }


    return (
        <div>
            <h2>
                Enter signup details
            </h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Will you need a bunk / bed:
                    </label>
                    <input 
                        onChange={() => setBedRequired(!bedRequired)}
                        defaultChecked={bedRequired}
                        type="checkbox"
                    />
                </div>
                <div>
                    <label>
                        How many nights are you staying
                        <input onChange={handler(setNoOfNights)} value={noOfNights} min="0" max="100" type="number"/>
                    </label>
                </div>
                <div>
                    <label>
                        Do you need a lift:
                    </label>
                    <input 
                        onChange={() => setNeedALift(!needALift)}
                        defaultChecked={needALift}
                        type="checkbox"
                    />
                </div>
                {
                    !needALift &&
                    <div>
                        <label>
                            If you can offer a lift - where from?:
                        </label>
                        <input 
                            onChange={handler(setLiftOffer)}
                            value={liftOffer}
                            type="text"
                        />
                    </div>  
                }
                {
                    needALift &&
                    <div>
                        <label>
                            Where do you need a lift from?:
                        </label>
                        <input 
                            onChange={handler(setLiftRequest)}
                            value={liftRequest}
                            type="text"
                        />
                    </div>  
                }
                <div>
                    <label>
                        What dietary requirements do you have?:
                    </label>
                    <input 
                        onChange={handler(setDietaryReqs)}
                        value={dietaryReqs}
                        type="text"
                    />
                </div>  
                <div>
                    <label>
                        What is your caving level?:
                        <select 
                            onChange={handler(setCavingLevel)}
                            value={cavingLevel}
                        >
                            <option value="0">Beginner</option>
                            <option value="1">Intermediate</option>
                            <option value="2">Advanced</option>
                            <option value="3">SpeleoPro</option>
                        </select>
                    </label>
                </div>
                <div>
                    <label>
                        Do you need to borrow any kit (club or otherwise):
                    </label>
                    <input 
                        onChange={() => setBorrowKit(!borrowKit)}
                        defaultChecked={borrowKit}
                        type="checkbox"
                    />
                </div>
                {
                    borrowKit &&
                    <div>
                        <label>
                            What kit do you need to borrow:
                        </label>
                        <input 
                            onChange={handler(setBorrowKitDetails)}
                            value={borrowKitDetails}
                            type="text"
                        />
                    </div>  
                }
                <div>
                    <label>
                        Can you / will you rig on this weekend?:
                    </label>
                    <input 
                        onChange={() => setCanRig(!canRig)}
                        defaultChecked={canRig}
                        type="checkbox"
                    />
                </div>
                <div>
                    <label>
                        Can you / will you lead on this weekend?:
                    </label>
                    <input 
                        onChange={() => setCanLead(!canLead)}
                        defaultChecked={canLead}
                        type="checkbox"
                    />
                </div>
                <div>
                    <label>
                        Any comments e.g. trips you want to do?:
                    </label>
                    <input 
                        onChange={handler(setComments)}
                        value={comments}
                        type="text"
                    />
                </div> 
                <GuestSignupDetails eventId={eventId} guestList={guestList} setGuestList={setGuestList} attendee={{bedRequired, noOfNights}}/>
                <div>
                    <button type="submit">
                        Sign up to trip
                    </button>
                </div>
            </form>

            <div>
                <h2>
                    Remove me and my guests from this trip
                </h2>
                <form onSubmit={handleDelete}>
                    <button type="submit">
                        Remove me and my guests from the table
                    </button>
                    <small> (Sorry you can't do this on a individual basis for now)</small>
                </form>
            </div>
        </div>
    )
}

export default EventSignupDetails
