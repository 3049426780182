import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { fetchUser } from '../backendApi/userApi'
import useAuth from '../hooks/useAuth'
import Layout from './layout'
import NavBar from './navBar/navBar'
import PersonalEventList from './PersonalEventList'

const Profile = () => {
    const { state } = useAuth()
    const [user, setUser] = useState(null)

    useEffect(() => {
        (async () => {
          const res = await fetchUser()
          setUser(res)
        })()
      }, [])
    return (
        <>
            <h1>
                Hi {user && user.personalDetails.firstName}
            </h1>
            <p>
                Thanks for signing up. If you have any questions about the club, feel free to message on the whatsapp group or contact a member of the committee. 
            </p>
            <p>
                Create a WSG Event / hut booking <Link to={"/app/event-create"}>here</Link>. 
            </p>
            <p>
                Create a trip report <Link to={"/app/trip-create"}>here</Link>. 
            </p>
            <AccountStatus user={user}/>
            <PersonalEventList userId={user?.id}/>
        </>
    )
}

const AccountStatus = ({user}) => {
    // TODO - add notice for paid or unpaid members
    if (!user) {
        return null
    }
    return (
        <>
                <h3>
                    Your account status
                </h3>
                    <div>You are a {user.personalDetails.wsgMembershipStatus} member.</div>
                    <div>Your membership type is {user.personalDetails.wsgMembershipType}.</div>
                    <div>Your BCA membership type is {user.personalDetails.bcaMembershipType}.</div>
                    { user.personalDetails.bcaNumber && <div>Your BCA number is {user.personalDetails.bcaNumber}.</div> }
        </>
    )
}


export default Profile 
