
export const concatName = (personalDetails: { firstName: any; otherNames: any; lastName: any }) => {
    let {firstName, otherNames, lastName} = personalDetails
    let initals = ''
    if (otherNames) {
        const middleNames = otherNames.split(' ')
        initals = middleNames.reduce((oldStr, newStr) => oldStr + newStr.charAt(0) + '. ', middleNames)
    } else {
    }
    return `${firstName} ${initals}${lastName}`
}
