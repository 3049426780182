import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { deleteAttendee, updateAttendee } from "../../backendApi/eventsApi"


const GuestSignupDetails = ({ eventId, guestList, setGuestList, attendee }) => {

    const [noOfGuests, setNoOfGuests] = useState(0)
    const handler = func => event => func(event.target.value)

    
    
    useEffect(() => {
        const defaultGuest = {
            ...attendee,
            "name": "",
            "phoneNumber": "",
            "needALift": false,
            "liftOffer": "",
            "liftRequest": "",
            "borrowKit": false,
            "borrowKitDetails": "",
            "dietaryReqs": "",
            "cavingLevel": 0,
            "canRig": false,
            "canLead": false,
            "comments": ""
        }
        // 1. when noOfGuests goes from 0, [] to 4, [] populate default list
        // 2. when noOfGuests goes from x to y increase default list
        if (guestList.length < noOfGuests) {
            setGuestList([...guestList, ...Array(noOfGuests - guestList.length).fill(defaultGuest)]) //fix
        }
        // 3. when noOfGuests goes from y to x decrease list
        if (guestList.length > noOfGuests) {
            setGuestList(guestList.slice(0, noOfGuests))
        }
        // 4. when noOfGuests goes from y to 0 empty list
        if (noOfGuests == 0) {
            setGuestList([])
        }
    }, [noOfGuests])

    return (
        <div>
            <h2>
                Guests / Partners / Children / etc.
            </h2>
            <div>
                <label>
                    How many extra people of yours are coming?: 
                </label>
                <select value={noOfGuests} onChange={handler(setNoOfGuests)}>
                    {[...Array(11).keys()].map((val, i) => (
                        <option value={val} key={i}>{val}</option>
                    ))}
                </select>
            </div>
            { noOfGuests > 0 &&

            <>
            {guestList.map((guest, i) => {
                return (
                    <GuestForm guest={guest} index={i} setGuestList={setGuestList} guestList={guestList} key={i}/>
                )
            })}
            </>

}
        </div>
    )
}

export default GuestSignupDetails


const GuestForm = ({ guest, index, setGuestList, guestList }) => {
 

    const [tempGuest, setTempGuest] = useState({
        "bedRequired": guest?.bedRequired,
        "noOfNights": guest?.noOfNights,
        "needALift": guest?.needALift,
        "liftOffer": guest?.liftOffer,
        "liftRequest": guest?.liftRequest,
        "borrowKit": guest?.borrowKit,
        "borrowKitDetails": guest?.borrowKitDetails,
        "dietaryReqs": guest?.dietaryReqs,
        "cavingLevel": guest?.cavingLevel,
        "canRig": guest?.canRig,
        "canLead": guest?.canLead,
        "comments": guest?.comments,
        "name": guest?.name,
        "phoneNumber": guest?.phoneNumber

    })


    const setGuest = (guest) => {
        const temp = [...guestList]
        temp[index] = guest
        setGuestList(temp)
    }

    useEffect(() => {
        setGuest(tempGuest)
    }, [tempGuest])

    

    return (
        <div>
            <h3>Person {index+1}:</h3>
        <div>
            <label>
                What is their name:
            </label>
            <input
            onChange={(e) => {setTempGuest({...tempGuest, name: e.target.value})}}
            value={tempGuest.name}
                type="text"
            />
        </div>
        <div>
            <label>
                What is their phone number:
            </label>
            <input
            onChange={(e) => {setTempGuest({...tempGuest, phoneNumber: e.target.value})}}
            value={tempGuest.phoneNumber}
                type="text"
            />
        </div>
        <div>
            <label>
                Will they need a bunk / bed:
            </label>
            <input
                onChange={() => {setTempGuest({...tempGuest, bedRequired: !tempGuest.bedRequired })}}
                defaultChecked={tempGuest.bedRequired}
                type="checkbox"
            />
        </div>
        <div>
            <label>
                How many nights are they staying
                <input 
                onChange={(e) => {setTempGuest({...tempGuest, noOfNights: e.target.value})}}
                value={tempGuest.noOfNights} 
                min="0" max="100" type="number" />
            </label>
        </div>
        <div>
            <label>
                Do they need a lift:
            </label>
            <input
                onChange={(e) => {setTempGuest({...tempGuest, needALift: !tempGuest.needALift})}}
                defaultChecked={tempGuest.needALift}
                type="checkbox"
            />
        </div>
        {
            !tempGuest.needALift &&
            <div>
                <label>
                    If they can offer a lift - where from?:
                </label>
                <input
                onChange={(e) => {setTempGuest({...tempGuest, liftOffer: e.target.value})}}
                value={tempGuest.liftOffer}
                    type="text"
                />
            </div>
        }
        {
            tempGuest.needALift &&
            <div>
                <label>
                    Where do you need a lift from?:
                </label>
                <input
                onChange={(e) => {setTempGuest({...tempGuest, liftRequest: e.target.value})}}
                value={tempGuest.liftRequest}
                    type="text"
                />
            </div>
        }
        <div>
            <label>
                What dietary requirements do they have?:
            </label>
            <input
                onChange={(e) => {setTempGuest({...tempGuest, dietaryReqs: e.target.value})}}
                value={tempGuest.dietaryReqs}
                type="text"
            />
        </div>
        <div>
            <label>
                What is their caving level?:
                <select
                onChange={(e) => {setTempGuest({...tempGuest, cavingLevel: e.target.value})}}
                value={tempGuest.cavingLevel}
                >
                    <option value="0">Beginner</option>
                    <option value="1">Intermediate</option>
                    <option value="2">Advanced</option>
                    <option value="3">SpeleoPro</option>
                </select>
            </label>
        </div>
        <div>
            <label>
                Do they need to borrow any kit (club or otherwise):
            </label>
            <input
                onChange={(e) => {setTempGuest({...tempGuest, borrowKit: !tempGuest.borrowKit})}}
                defaultChecked={tempGuest.borrowKit}
                type="checkbox"
            />
        </div>
        {
            tempGuest.borrowKit &&
            <div>
                <label>
                    What kit do they need to borrow:
                </label>
                <input
                onChange={(e) => {setTempGuest({...tempGuest, borrowKitDetails: e.target.value})}}
                value={tempGuest.borrowKitDetails}
                    type="text"
                />
            </div>
        }
        <div>
            <label>
                Can they rig on this weekend?:
            </label>
            <input
                onChange={(e) => {setTempGuest({...tempGuest, canRig: !tempGuest.canRig})}}
                defaultChecked={tempGuest.canRig}
                type="checkbox"
            />
        </div>
        <div>
            <label>
                Can they lead on this weekend?:
            </label>
            <input
                onChange={(e) => {setTempGuest({...tempGuest, canLead: !tempGuest.canLead})}}
                defaultChecked={tempGuest.canLead}
                type="checkbox"
            />
        </div>
        <div>
            <label>
                Any comments e.g. trips they want to do?:
            </label>
            <input
                onChange={(e) => {setTempGuest({...tempGuest, comments: e.target.value})}}
                value={tempGuest.comments}
                type="text"
            />
        </div>
    </div>
    )
}